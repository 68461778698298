import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
  return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
  return originalReplace.call(this , location).catch(err=>err)
}

const routes = [
  {
    path: '/parkCarrier',
    name: 'parkCarrier',
    component: () => import(/* webpackChunkName: "about" */ '../views/parkCarrier/index.vue')
  },
  {
    path: '/parkCarrier/detail/:id',
    name: 'parkCarrierDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/parkCarrier/detail/index.vue')
  },
  {
    path: '/economic',
    name: 'economic',
    component: () => import(/* webpackChunkName: "about" */ '../views/economic/index.vue')
  },
  {
    path: '/economic/detail/:id',
    name: 'economicDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/economic/detail/index.vue')
  },
  {
    path: '/bizDemand',
    name: 'bizDemand',
    component: () => import(/* webpackChunkName: "about" */ '../views/bizDemand/index.vue')
  },
  {
    path: '/bizPolicy',
    name: 'bizPolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/bizPolicy/index.vue')
  },
  {
    path: '/talentPolicy',
    name: 'talentPolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/talentPolicy/index.vue')
  },
  {
    path: '/localIndustry',
    name: 'localIndustry',
    component: () => import(/* webpackChunkName: "about" */ '../views/localIndustry/index.vue')
  },
  {
    path: '/415x',
    name: '415x',
    component: () => import(/* webpackChunkName: "about" */ '../views/415x/index.vue')
  }
]

const router = new VueRouter({
  mode:'hash',
  // base:'/high-pc/',
  // mode: 'history',
  //  base: '/zj/',
  // base: process.env.BASE_URL,
  routes
})

export default router
